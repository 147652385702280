import styles from './Tabs.module.scss';

import { ReactElement, useState } from 'react';

import TabTitle from './TabTitle';

type Tabs = {
  children: ReactElement[];
};

const Tabs: React.FC<Tabs> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className={styles.tabs}>
      <ul className={styles.tabs__list}>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            setSelectedTab={setSelectedTab}
            active={index === selectedTab}
          />
        ))}
      </ul>
      <div className={styles.tabs__stage}>{children[selectedTab]}</div>
    </div>
  );
};

export default Tabs;
