import React, { useEffect, useRef } from 'react';
import { ComponentFeaturedItems } from '../ComponentFeaturedItems';
import { ComponentFiftyFiftyCallout } from '../ComponentFiftyFiftyCallout';

import styles from './ComponentAccordion.module.scss';

import { ComponentAccordionFields } from '@/types';

interface AccordionItemProps {
  data: any;
  defaultOpen: boolean;
}

const AccordionItem = ({ data, defaultOpen }: AccordionItemProps) => {
  const renderAccordionContent = (content: any) => {
    if (content.__typename === 'ComponentFeaturesItems') {
      return <ComponentFeaturedItems {...content} />;
    }
    if (content.__typename === 'ComponentFiftyFiftyCallout') {
      return <ComponentFiftyFiftyCallout {...content} />;
    }
    return null;
  };

  const accordionTitleRef = useRef<HTMLButtonElement>(null);
  const accordionContentRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  const handleAccordionClick = () => {
    if (accordionContentRef.current && accordionTitleRef.current) {
      setIsOpen(!isOpen);
    }
  };

  // Derive the accordion title based on the content type
  let derivedAccordionTitle = '';
  if (data.__typename === 'ComponentFiftyFiftyCallout') {
    derivedAccordionTitle = data.calloutTitle;
  } else if (data.__typename === 'ComponentFeaturesItems') {
    derivedAccordionTitle = data.informationListingTitle;
  }

  return (
    <li className={styles.accordion__item}>
      <button
        ref={accordionTitleRef}
        className={`${styles.accordion__itemTitle} ${
          isOpen ? styles.active : styles.closed
        }`}
        tabIndex={0}
        aria-expanded={isOpen}
        onClick={handleAccordionClick}
      >
        {derivedAccordionTitle}
      </button>
      <div
        ref={accordionContentRef}
        className={`${styles.accordion__itemContent} ${
          isOpen ? styles.active : styles.closed
        } `}
      >
        <div
          key={`acc_content_${data.sys?.id}`}
          className={`${styles.accordion__contentWrapper} ${
            !isOpen ? styles.hiddenContent : styles.visibleContent
          }`}
        >
          {renderAccordionContent(data)}
        </div>
      </div>
    </li>
  );
};

const ComponentAccordion = ({
  accordionHeading,
  accordionItems,
}: ComponentAccordionFields) => {
  return (
    <section className={styles.accordion}>
      <div className={`${styles.accordion__inner}`}>
        {accordionHeading && (
          <h2 className={styles.accordion__heading}>{accordionHeading}</h2>
        )}

        {accordionItems && (
          <ul className={`${styles.accordion__list}`}>
            {accordionItems.map((accordionItem, index) => {
              return (
                <AccordionItem
                  key={accordionItem.sys?.id}
                  data={accordionItem}
                  defaultOpen={index === 0}
                />
              );
            })}
          </ul>
        )}
      </div>
    </section>
  );
};

export default ComponentAccordion;
