import styles from './ComponentFeatureTabs.module.scss';

import Tab from './Tab';
import Tabs from './Tabs';
import TabContentIHB from './TabContentIHB';
import type { ImageHeadingBodyItem } from '@/types';

import ComponentContentTabs from './ComponentContentTabs';

const FeatureTabs = ({
  tabbedTitle,
  tabbedHeading,
  tabbedStyle,
  tabbedContent,
  splitTabbedContentText,
  presentationType,
  displayMode,
}: {
  tabbedHeading: string;
  tabbedTitle: string;
  tabbedStyle: string;
  tabbedContent: any[];
  splitTabbedContentText: boolean;
  presentationType: string;
  displayMode?: string;
}) => {
  const showHeading = tabbedHeading?.length > 0;
  const type = presentationType?.toLowerCase() || 'simple';
  const typename =
    type == 'simple' ? 'DataImageHeadingBody' : 'ComponentContentTab';
  const tabContents = tabbedContent?.filter(
    (tab: any) => tab.__typename === typename
  );

  if (type == 'simple') {
    return (
      <div
        key={tabbedTitle}
        className={`${styles.featureTabs} ${
          tabbedStyle.toLowerCase() === 'light' ? styles.light : styles.accent
        } `}
      >
        <div className={styles.featureTabs__container}>
          {showHeading && (
            <h2 className={styles.featureTabs__heading}>{tabbedHeading}</h2>
          )}
          <Tabs>
            {tabContents.map((tab: ImageHeadingBodyItem, index: number) => (
              <Tab
                key={'key_' + tab.ihbHeading + '_' + index}
                title={tab.ihbHeading}
              >
                <TabContentIHB {...tab} splitText={splitTabbedContentText} />
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    );
  } else {
    return (
      <ComponentContentTabs content={tabContents} displayMode={displayMode} />
    );
  }
};

export default FeatureTabs;
