import React, { useState, useRef } from 'react';
import styles from './ComponentFeatureTabs.module.scss';

import { ComponentFiftyFiftyCallout } from '../ComponentFiftyFiftyCallout';
import { ComponentAccordion } from '../ComponentAccordion';
import { ComponentRichTextBody } from '../ComponentRichTextBody';
import { ComponentFeaturedItems } from '../ComponentFeaturedItems';
import { ComponentTestimonialCarousel } from '../ComponentTestimonialCarousel';

interface Tab {
  sys: {
    id: string;
  };
  heading: string;
  subheading: string;
  contentArea: Array<any>;
}

const ComponentContentTabs = ({
  content,
  displayMode = 'light',
}: {
  content: Tab[];
  displayMode?: string;
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabWrapperRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
    const tabElement = tabRefs.current[index];
    if (tabElement && tabWrapperRef.current) {
      const tabWrapperRect = tabWrapperRef.current.getBoundingClientRect();
      const tabElementRect = tabElement.getBoundingClientRect();
      if (
        tabElementRect.left < tabWrapperRect.left ||
        tabElementRect.right > tabWrapperRect.right
      ) {
        tabElement.scrollIntoView({
          inline: 'center',
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <div className={styles.contentTabsContainer}>
      <div
        className={`${styles.contentTabs} ${
          displayMode.toLowerCase() === 'light' ? styles.light : styles.dark
        }`}
      >
        <div className={styles.contentTabs__container}>
          <div className={styles.contentTabs__tabWrapper} ref={tabWrapperRef}>
            {content.map((item, index) => {
              const randomKey = Math.random().toString(36).substring(7);
              return (
                <div
                  ref={(el) => (tabRefs.current[index] = el)}
                  className={`${styles.contentTabs__tab} ${
                    index === selectedTab ? styles.activeTab : ''
                  }`}
                  key={`tab_${item.sys.id}__${randomKey}`}
                  onClick={() => handleTabClick(index)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleTabClick(index);
                    }
                  }}
                  tabIndex={0}
                  role='button'
                >
                  <h3 className={styles.contentTabs__tabHeading}>
                    {item.heading}
                  </h3>
                  <p className={styles.contentTabs__tabSubheading}>
                    {item.subheading}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={`${styles.contentTabs__contentContainer}`}>
        {content.map((item, index) => {
          const randomKey = Math.random().toString(36).substring(7);
          return (
            <div
              key={`content_${item.sys.id}_${randomKey}`}
              className={`${styles.contentTabs__content} ${
                index === selectedTab ? styles.activeContent : ''
              }`}
            >
              {item.contentArea.map((content) => {
                if (content.__typename === 'ComponentFiftyFiftyCallout') {
                  return (
                    <ComponentFiftyFiftyCallout
                      key={`${content.sys.id}__${randomKey}`}
                      {...content}
                    />
                  );
                }
                if (content.__typename === 'ComponentAccordion') {
                  return (
                    <ComponentAccordion
                      key={`${content.sys.id}__${randomKey}`}
                      {...content}
                    />
                  );
                }
                if (content.__typename === 'ComponentRichText') {
                  return (
                    <ComponentRichTextBody
                      key={`${content.sys.id}__${randomKey}`}
                      {...content}
                    />
                  );
                }
                if (content.__typename === 'ComponentFeaturesItems') {
                  return (
                    <ComponentFeaturedItems
                      key={`${content.sys.id}__${randomKey}`}
                      {...content}
                    />
                  );
                }
                if (content.__typename === 'ComponentTestimonialCarousel') {
                  return (
                    <ComponentTestimonialCarousel
                      key={`${content.sys.id}__${randomKey}`}
                      {...content}
                    />
                  );
                }
                return null;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ComponentContentTabs;
